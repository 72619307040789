<template>
  <div id="CUSTOMER_BADGE_GROUP_MODAL" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" footer-class="footerClass" title="Edit Assigned Customer"
      no-close-on-backdrop  content-class="shadow"
      @hide="modalCancel"
      scrollable
    >
      <b-form-group>
        <label class="mr-1">{{ $t(`project.field.customers`) }}</label>
        <button class="btn-action" @click="customerSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/></button>
      
        <BadgeGroup v-model="customers">
          <template v-slot:default="{ item, index }">
            <Badge @badgeRemove="customerBadgeRemove(index)"
              :text="`${item.name}`" 
              variant="primary"
              :pillable="!!item.pillable" :key="index" />
            </template>
        </BadgeGroup>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('badgeGroupError') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <CustomerSelectorModal v-if="customerSelectorShow" :show.sync="customerSelectorShow" @cancel="customerSelectorCancel" @ok="customerSelectorOk"/>
    
  </div>
</template>

<script>
import { objectClone } from '@/helpers'
import { fieldValidateUtil } from '@/script/helper-field-validate'

export default {
  name: 'CustomerBadgeGroupModal',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    CustomerSelectorModal: () => import('@/components/modal/CustomerSelectorModal'),
    
  },
  props: {
    show: { type: Boolean, required: true }
    , customerList: { type: Array, default: () => [] }
    , isOptional: { type: Boolean, default: false }
  },
  data() {
    return {
      modalShow: false
      , customerSelectorShow: false
      , customers: []
    }
  },
  created() {
    this.fieldValidateUtil = fieldValidateUtil
    this.modifiedList = []
  },
  beforeMount() {
    this.customers.splice(0, this.customers.length, ...this.customerList)
    this.modalShow = true
  },
  mounted() {

  },
  beforeDestroy() {
    this.modifiedList = null
    this.fieldValidateUtil = null
  },
  watch: {
    show(newValue) {
      if (newValue != this.modalShow) {
        this.errors.clear()
        this.modifiedList.splice(0, this.modifiedList.length)
        this.modalShow = newValue
        if (newValue) {
          this.customers.splice(0, this.customers.length, ...this.customerList)
        }
      }
    }
  },
  computed: {
    showError() {
      return fieldValidateUtil.hasError(this.errors, 'badgeGroupError');
    },
  },
  methods: {
    modalCancel() {
      //listen to hide instead of hidden event, this.modifiedList has not been reset yet in hide event
      this.$emit('cancel', { modifiedList: objectClone(this.modifiedList) })
    }
    , customerSelectorCancel({ modifiedList=[] }={}) {
      if (modifiedList.length > 0) {
        this.modifiedList.push(...modifiedList)
      }
      this.customerSelectorShow = false
    }
    , customerBadgeRemove: function(index) {
      this.customers.splice(index,1)
    }
    , customerSelectorToggle() {
      this.customerSelectorShow = true
    }
    , customerSelectorOk({ details }) {
      this.errors.clear()
      this.customers.push( ...details.map(i => { return { uuId: i.uuId, name: i.name }}) )
    }
    , ok() {
      this.errors.clear()
      if (!this.isOptional && this.customers.length < 1) {
        this.errors.add({
          field: 'badgeGroupError',
          msg: this.$i18n.t('error.missing_argument', [this.$t(`project.field.customers`)])
        })
      }

      this.$nextTick(() => {
        if (this.errors.items.length < 1) {
          this.$emit('ok', objectClone(this.customers))
          this.$emit('update:show', false)
        }
      })
    }
  }
}
</script>